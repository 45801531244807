/*style for sticky footer*/
html {
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#main {
  flex: 1 0 auto; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  transition: all 0.3s;
}
footer {
  flex-shrink: 0; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
}

.navStyles {
  background-color: #3D3B3B;
  padding: 0 2rem;
}

.navStyles-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(35 39 47/var(--tw-bg-opacity));
  padding: 0 2rem;
  --tw-text-opacity: 1;
  color: rgb(235 236 240/var(--tw-text-opacity));
}

.page-footer-dark {
  padding: 20px 0;
  --tw-bg-opacity: 1;
  background-color: rgb(35 39 47/var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(235 236 240/var(--tw-text-opacity));
}
.page-footer-light{
  padding: 20px 0;
  background-color: #3D3B3B;
  --tw-text-opacity: 1;
  color: rgb(235 236 240/var(--tw-text-opacity));
}
.rv-card-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(35 39 47/var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(235 236 240/var(--tw-text-opacity)) !important;
}

.btnCdark {
  background-color: transparent;
}
.btnClight {
  color: black;
}
.mobileThemeToggleIcon {
  max-width: 50px;
  margin: auto;
}
/* Animation for Project Items */

/*React transition group*/
.item-enter {
  opacity: 0;
  transform: translateX(-100px);
}
.item-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}
.item-exit {
  opacity: 1;
  transform: translateX(0);
}
.item-exit-active {
  opacity: 0;
  transform: translateX(100px);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

/* Animation for Resume and TechnicalSkills Block */
.cardAnimation {
  transition: background-color 0.5s !important;
}
.cardAnimation:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.cardAnimation-dark {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.cardAnimation-dark:hover {
  background-color: rgba(0, 0, 0, 0.35) !important;
}

/* Animation for buttons */
.btn:hover {
  background-color: rgba(0, 0, 0, 0.85) !important;
  transition: background-color 0.5s !important;
}
.toggleTheme, .toggleTheme:hover, .toggleTheme:active, .toggleTheme:focus {
  border: none;
  background: rgba(0, 0, 0, 0);
} 

.theme-icon {
  margin-top: 1rem;
  margin-left: 0.5rem;
}

.dark-theme {
    --tw-text-opacity: 1;
    color: rgb(235 236 240/var(--tw-text-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(35 39 47/var(--tw-bg-opacity));
    transition: all 0.3s;
}

/* Styles for positioning skills block */
@media only screen and (min-width: 400px) {
  .iconPos {
    position: absolute;
    top: 1.2rem;
    left: 0.5rem;
  }
}
@media only screen and (max-width: 400px) {
  .iconPos {
    display: none;
  }
}
@media only screen and (min-width: 750px) {
  .iconPos {
    position: absolute;
    top: 1.2rem;
    left: 0.4rem;
  }
}
@media only screen and (min-width: 900px) {
  .iconPos {
    position: absolute;
    top: 1.2rem;
    left: 1rem;
  }
}
